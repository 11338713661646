import './App.css';
import React, { Component } from 'react';




let contract;

class App extends Component {

	state = {
		
	}

	render() {

		return (

			<div class="allWrap">

			</div >)
	}
}

export default App;
